import apiCall from '../../apiCall/apiCall';

// Handles all carbon emission requests.
class CarbonEmissions {
  static job(job) {
    return apiCall('GET', `jobs/bookings/${job}/emissions`);
  }
  static vehicleRun(run) {
    return apiCall('GET', `movements/vehicle_runs/${run}/emissions`);
  }
}

export default CarbonEmissions;
