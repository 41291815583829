import React, { useEffect, useState } from 'react';
import './CO2VehicleRunDropdown.scss';
import CarbonEmissions from '../../../../../../../../api/controllers/CarbonEmissions/CarbonEmissions';
import PropTypes from 'prop-types';
import CollapsibleFormSection from '../../../../../../../../components/CollapsibleFormSection/CollapsibleFormSection';
import { faSpinner, faTruck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { labelForEnum } from '../../../../../../../../utils/EnumHelper/EnumHelper';
import { fuelTypes, vehicleTypes } from '../../../../../../../../strings/enums';
import CO2Lines from '../../../../../../../../components/CO2Lines/CO2Lines';

const CO2VehicleRunDropdown = ({ run }) => {
  const [carbonEmissions, setCarbonEmissions] = useState();
  const [totalDistance, setTotalDistance] = useState();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    if (dropdownOpen && !carbonEmissions) {
      setError(false);
      setLoading(true);
      CarbonEmissions.vehicleRun(run.id)
        .then(result => {
          setLoading(false);
          setCarbonEmissions(result);
          setTotalDistance(
            result.breakdown.length == 0
              ? -1
              : result.breakdown
                  .map(i => i.distance || 0)
                  .reduce((partialSum, a) => partialSum + a, 0),
          );
        })
        .catch(e => {
          setLoading(false);
          setError(e.error);
        });
    }
  }, [dropdownOpen]);

  const getDistanceText = () => {
    if (loading)
      return <FontAwesomeIcon icon={faSpinner} className={'fa-spin'} data-testid={'spinner'} />;
    if (!totalDistance) return 'Expand to calculate total distance';
    if (totalDistance == -1) return 'No route details available; distance cannot be calculated';
    else return `${Number(totalDistance).toFixed(2)} km`;
  };

  return (
    <div className={'tab-content vehicle-run-co2'}>
      <CollapsibleFormSection
        showTitleOnHover={false}
        title={
          <div className="co2-dropdown-header">
            <FontAwesomeIcon icon={faTruck} />
            {labelForEnum(vehicleTypes, run?.vehicle?.kind)}
          </div>
        }
        onClick={() => setDropdownOpen(!dropdownOpen)}
        open={dropdownOpen}
        optionalElement={
          <div className={`dropdown-header-stats`}>
            <div className="bold">
              Fuel:
              <div className="info">{`${
                labelForEnum(fuelTypes, run?.vehicle?.fuel) || 'N/A'
              }`}</div>
            </div>
            <div className="bold">
              Capacity:
              <div className="info">
                {run?.vehicle?.weightLimitKg ? `${run?.vehicle?.weightLimitKg}kg` : 'N/A'}
              </div>
            </div>
            <div className="info">{getDistanceText()}</div>
          </div>
        }
      >
        <CO2Lines
          type="vehicleRun"
          items={carbonEmissions?.breakdown || []}
          loading={loading}
          error={error}
        />
      </CollapsibleFormSection>
    </div>
  );
};

CO2VehicleRunDropdown.propTypes = {
  run: PropTypes.object,
};

export default CO2VehicleRunDropdown;
