import apiCall from '../../apiCall/apiCall';
import buildGetQuery from '../../helpers/buildGetQuery/buildGetQuery';

class Report {
  static all(params) {
    const data = buildGetQuery(params);
    return apiCall('GET', 'reports/standard', data);
  }
  static show(id, params) {
    const data = buildGetQuery(params);
    return apiCall('GET', `reports/standard/${id}`, data);
  }
  static allBackground(params) {
    const data = buildGetQuery(params);
    return apiCall('GET', 'reports/background_tasks', data);
  }
  static showBackground(id) {
    return apiCall('GET', `reports/background_tasks/${id}`);
  }
  static schedule(id, params) {
    const data = buildGetQuery(params);
    return apiCall('POST', `reports/standard/${id}/schedule`, data);
  }
  static deleteReport(id) {
    return apiCall('DELETE', `reports/background_tasks/${id}`);
  }
}

export default Report;
