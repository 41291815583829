import React, { useEffect, useState } from 'react';
import FormSection from '../../../../../../components/FormSection/FormSection';
import './VehicleRunShowCO2.scss';
import PropTypes from 'prop-types';
import Booking from '../../../../../../api/controllers/Job/Booking/Booking';
import LoadingSpinner from '../../../../../../common/LoadingSpinner/LoadingSpinner';
import CO2JobDropdown from '../../../../../Company/Entity/EntityShow/subComponents/CO2Tab/subComponents/CO2JobDropdown/CO2JobDropdown';
import CO2VehicleRunDropdown from './subComponents/CO2VehicleRunDropdown/CO2VehicleRunDropdown';

const VehicleRunShowCO2 = ({ run }) => {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [jobs, setJobs] = useState([]);

  useEffect(() => {
    setLoading(true);
    setError(false);
    Booking.all({ vehicleRunId: run.id })
      .then(result => {
        setLoading(false);
        setJobs(result.items);
      })
      .catch(e => {
        setLoading(false);
        setError(e.error);
      });
  }, []);

  const getSectionContent = () => {
    return jobs.length > 0 ? (
      jobs?.map(job => {
        return <CO2JobDropdown key={job.id} job={job} />;
      })
    ) : (
      <div className="no-jobs">
        {loading ? <LoadingSpinner /> : error || 'No jobs were booked for this vehicle run'}
      </div>
    );
  };

  return (
    <div className={'tab-content'}>
      <FormSection title={'CO₂'}>
        <CO2VehicleRunDropdown run={run} />
        {getSectionContent()}
      </FormSection>
    </div>
  );
};

VehicleRunShowCO2.propTypes = {
  run: PropTypes.object,
};

export default VehicleRunShowCO2;
