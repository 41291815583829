import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '../../../common/button/IconButton/IconButton';
import { faFerry, faLocationDot, faTrain } from '@fortawesome/free-solid-svg-icons';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import { fuelTypes, vehicleTypes } from '../../../strings/enums';
import { labelForEnum } from '../../../utils/EnumHelper/EnumHelper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CO2LinesRow = ({ type, factors, item, weight }) => {
  const getIntensityRatio = (factors, distance, weight) => {
    if (factors.tkm) {
      return (Number(factors.tkm.ttw) + Number(factors.tkm.wtt)).toFixed(2);
    } else if (factors.km && type == 'vehicleRun') {
      return (
        (Number(factors.km.ttw) + Number(factors.km.wtt)) /
        (Number(distance) * (Number(weight) / 1000))
      ).toFixed(2);
    } else {
      return 0;
    }
  };

  const getFactorHoverText = (factors, column) => {
    if (type == 'vehicleRun' && factors.km) {
      return column == 'ttw' ? `km = ${factors.km.ttw}` : `km = ${factors.km.wtt}`;
    } else if (factors.tkm) {
      return column == 'ttw' ? `tkm = ${factors.tkm.ttw}` : `tkm = ${factors.tkm.wtt}`;
    } else {
      return `tkm = 0`;
    }
  };

  return (
    <tr>
      <td className={'small'}>
        <div className="location">
          <div className="location_buttons">
            <IconButton
              iconOnly
              className="open-maps-button location"
              testId={`-open-maps-button`}
              iconClassName="open-maps-icon"
              icon={faLocationDot}
              onClick={() =>
                window.open(
                  `http://www.openstreetmap.org/?mlat=${item.latitude}&mlon=${item.longitude}&zoom=12`,
                  '_blank',
                )
              }
              disabled={!(item.latitude && item.longitude)}
            />
            <IconButton
              iconOnly
              className="google-maps-button location"
              testId={`-google-maps-button`}
              iconClassName="google-maps-icon"
              icon={faGoogle}
              onClick={() =>
                window.open(
                  `https://maps.google.com/?q=${item.latitude},${item.longitude}`,
                  '_blank',
                )
              }
              disabled={!(item.latitude && item.longitude)}
            />
          </div>
          {item.reference}
        </div>
      </td>
      {type == 'vehicleRun' ? (
        <td className={'small'}>
          {item.distance && item.capacity ? (
            <div>{((weight / item.capacity) * 100).toFixed(0)}%</div>
          ) : (
            <div className="missing">N/A</div>
          )}
        </td>
      ) : (
        <td className={'small'}>
          {item.distance ? (
            <div
              className="tooltip"
              title={`${
                item.fuel ? `${labelForEnum(fuelTypes, item.fuel)} ` : '(Not in database) '
              }(${
                item.capacity || item.totalWeight
                  ? `${item.capacity ? `${item.capacity}kg maximum` : ''}${
                      item.capacity && item.totalWeight ? ', ' : ''
                    }${item.totalWeight ? `${item.totalWeight}kg present` : ''}`
                  : 'Not present'
              })`}
            >
              {labelForEnum(vehicleTypes, item.vehicle)}
              {item.capacity && ` | ${((item.totalWeight / item.capacity) * 100).toFixed(0)}%`}
            </div>
          ) : (
            <div className="missing">N/A</div>
          )}
        </td>
      )}
      <td className={'small'}>
        <div
          className="tooltip"
          title={item.distance && `${(Number(item.distance) / 1.609).toFixed(2)} miles`}
        >
          {item.distance && Number(item.distance).toFixed(2)}
        </div>
      </td>
      <td className={'small'}>
        {item.distance && (
          <div
            className={item.distance && 'tooltip'}
            title={
              item.distance && `${(Number(item.distance) * (Number(weight) / 1000)).toFixed(2)}t-km`
            }
          >
            {weight}
          </div>
        )}
      </td>
      <td className={'small'}>
        {item.distance && (
          <div className="tooltip" title={getFactorHoverText(item.factors, 'wtt')}>
            {(
              Number(item.distance) *
              Number(factors.wtt) *
              (factors.type == 'tkm' ? weight / 1000 : 1)
            ).toFixed(2)}
          </div>
        )}
      </td>
      <td className={'small'}>
        {item.distance && (
          <div className="tooltip" title={getFactorHoverText(item.factors, 'ttw')}>
            {(
              Number(item.distance) *
              Number(factors.ttw) *
              (factors.type == 'tkm' ? weight / 1000 : 1)
            ).toFixed(2)}
          </div>
        )}
      </td>
      <td className={'small'}>
        {item.distance && (
          <div
            className="tooltip"
            title={`intensity ratio: ${getIntensityRatio(item.factors, item.distance, weight)}kg`}
          >
            {(
              Number(item.distance) *
                Number(factors.wtt) *
                (factors.type == 'tkm' ? weight / 1000 : 1) +
              Number(item.distance) *
                Number(factors.ttw) *
                (factors.type == 'tkm' ? weight / 1000 : 1)
            ).toFixed(2)}
          </div>
        )}
      </td>
      {type == 'vehicleRun' && (
        <td className={'small'}>
          <div className="tooltip" title={item.vehicle == 'roro_ferry' ? 'Ferry' : 'Train'}>
            {item.vehicle == 'roro_ferry' && (
              <FontAwesomeIcon icon={faFerry} data-testid={'ferry-icon'} />
            )}
            {item.vehicle == 'freight_train' && (
              <FontAwesomeIcon icon={faTrain} data-testid={'train-icon'} />
            )}
          </div>
        </td>
      )}
    </tr>
  );
};

CO2LinesRow.propTypes = {
  type: PropTypes.oneOf(['job', 'vehicleRun']),
  factors: PropTypes.object,
  item: PropTypes.object,
  weight: PropTypes.number,
};

export default CO2LinesRow;
