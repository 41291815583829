import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { faTrash, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import './ScheduledReportRow.scss';
import IconButton from '../../../../common/button/IconButton/IconButton';
import { useNavigate } from 'react-router';
import Entity from '../../../../api/controllers/Company/Entity/Entity';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DateHelper from '../../../../utils/DateHelper/DateHelper';

const ScheduledReportRow = ({ row, deleteRow, handleError }) => {
  const navigate = useNavigate();
  const [paramsText, setParamsText] = useState(null);

  useEffect(() => {
    const paramsObject = JSON.parse(row.reportParams);
    if (paramsObject.entity_id) {
      Entity.show(paramsObject.entity_id)
        .then(entity => {
          setParamsText(
            `Start Date: ${DateHelper.dateOnly(
              paramsObject.start_date,
            )} / End Date: ${DateHelper.dateOnly(paramsObject.end_date)} / Entity: ${
              entity.internalName
            }`,
          );
        })
        .catch(error => {
          handleError(error);
          setParamsText(
            `Start Date: ${DateHelper.dateOnly(
              paramsObject.start_date,
            )} / End Date: ${DateHelper.dateOnly(paramsObject.end_date)}`,
          );
        });
    } else {
      setParamsText(
        `Start Date: ${DateHelper.dateOnly(
          paramsObject.start_date,
        )} / End Date: ${DateHelper.dateOnly(paramsObject.end_date)}`,
      );
    }
  }, []);

  return (
    <tr className="report-row" data-testid="report-row">
      <td data-testid="srr-name">
        <span
          className={row.complete && !row.errorMessage && 'srr-link'}
          onClick={() =>
            row.complete && !row.errorMessage && navigate(`/reports/background_jobs/${row.id}`)
          }
        >
          {row.description}
        </span>
      </td>
      <td data-testid="srr-user">{`${row.user.firstName} ${row.user.lastName}`}</td>
      <td data-testid="srr-date-req">{DateHelper.format(row.createdAt)}</td>
      <td data-testid="srr-parameters" className="srr-parameters">
        {paramsText}
      </td>
      <td
        data-testid="srr-status"
        className={`small srr-status ${
          row.errorMessage ? 'failed' : row.complete ? 'complete' : ''
        }`}
      >
        <div className="status-container">
          {row.errorMessage ? 'Failed' : row.complete ? 'Complete' : 'Running'}
          {row.errorMessage && (
            <FontAwesomeIcon
              icon={faTriangleExclamation}
              color={'red'}
              title={row.errorMessage}
              className="status-icon"
            />
          )}
        </div>
      </td>
      <td data-testid="srr-actions" className="small">
        {
          <IconButton
            text={'Delete'}
            icon={faTrash}
            className={'destructive small report-button'}
            onClick={() => deleteRow(row.id)}
          />
        }
      </td>
    </tr>
  );
};

ScheduledReportRow.propTypes = {
  row: PropTypes.object.isRequired, // A single Reports::Standard object
  deleteRow: PropTypes.func.isRequired, // Handles special logic upon redirect
  handleError: PropTypes.func.isRequired, // Handles if entity api call fails
};

export default ScheduledReportRow;
