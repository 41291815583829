import React, { useState } from 'react';
import './ScheduleReportIndex.scss';
import useApi from '../../../../common/hooks/useApi/useApi';
import Report from '../../../../api/controllers/Report/Report';
import { formatError } from '../../../../utils/Formatters/Errors/formatError';
import Table from '../../../../components/Table/Table/Table';
import LoadingSpinner from '../../../../common/LoadingSpinner/LoadingSpinner';
import ScheduledReportRow from '../ScheduledReportRow/ScheduledReportRow';
import { faRefresh } from '@fortawesome/free-solid-svg-icons';
import IconButton from '../../../../common/button/IconButton/IconButton';
import PropTypes from 'prop-types';
import ConfirmationModal from '../../../../components/ConfirmationModal/ConfirmationModal';

const scheduledHeaders = [
  { text: 'Name', sortingAttribute: 'description' },
  { text: 'User', sortingAttribute: 'user' },
  { text: 'Started', sortingAttribute: 'createdAt' },
  { text: 'Parameters' },
  { text: 'Status' },
  { text: 'Actions' },
];

const ScheduleReportIndex = ({ setMessage }) => {
  /* Handles errors coming from the Report.allBackground endpoint */
  const handleError = err => {
    setMessage({ type: 'error', text: formatError(err) });
  };

  const [showModal, setShowModal] = useState(false);
  const { backgroundJobs, backgroundJobsLoading, refreshBackgroundJobs } = useApi({
    call: Report.allBackground,
    name: 'backgroundJobs',
    handleError,
  });

  const handleDelete = async id => {
    await Report.deleteReport(id);
    setShowModal(false);
    refreshBackgroundJobs();
  };

  return (
    <div>
      {showModal && (
        <ConfirmationModal
          question={'Are you sure you want to delete this report?'}
          confirmText={'Delete'}
          isOpen={showModal}
          handleConfirm={() => handleDelete(showModal)}
          handleCancel={() => setShowModal(false)}
        />
      )}
      {backgroundJobsLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="reports-table-container">
          <Table
            defaultSort={{ sort: 'createdAt', order: 'desc' }}
            title={'Scheduled Reports'}
            headers={scheduledHeaders}
            data={backgroundJobs}
            searchable={[
              'description',
              'user.firstName',
              'user.lastName',
              'createdAt',
              'reportParams',
            ]}
            onDrawRow={row => (
              <ScheduledReportRow
                key={row.id}
                row={row}
                handleError={handleError}
                deleteRow={setShowModal}
              />
            )}
            childrenBeforeSearch
          >
            <IconButton
              text={'Refresh'}
              icon={faRefresh}
              className={'import-job small'}
              onClick={() => refreshBackgroundJobs()}
            />
          </Table>
        </div>
      )}
    </div>
  );
};

export default ScheduleReportIndex;

ScheduleReportIndex.propTypes = {
  setMessage: PropTypes.func.isRequired,
};
