import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router';
import Breadcrumbs from '../../../../components/Breadcrumbs/Breadcrumbs';
import { Link } from 'react-router-dom';
import VehicleRun from '../../../../api/controllers/Movement/VehicleRun/VehicleRun';
import useApi from '../../../../common/hooks/useApi/useApi';
import LoadingSpinner from '../../../../common/LoadingSpinner/LoadingSpinner';
import NoPage from '../../../NoPage/NoPage';
import './VehicleRunShow.scss';
import IconButton from '../../../../common/button/IconButton/IconButton';
import {
  faEnvelope,
  faFile,
  faLock,
  faLockOpen,
  faPen,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import Ribbon from '../../../../components/Ribbon/Ribbon';
import TabGroup from '../../../../components/TabGroup/TabGroup';
import VehicleRunShowGeneral from './subComponents/VehicleRunShowGeneral/VehicleRunShowGeneral';
import NotesTab from '../../../Company/Entity/EntityShow/subComponents/NotesTab/NotesTab';
import AttachmentsTab from '../../../../components/AttachmentsTab/AttachmentsTab';
import Message from '../../../../common/layout/Message/Message';
import EmailComposeModal from '../../../../components/EmailComposeModal/EmailComposeModal';
import ErrorStatusMessage from '../../../../common/ErrorStatusMessage/ErrorStatusMessage';
import { formatError } from '../../../../utils/Formatters/Errors/formatError';
import ConfirmationModal from '../../../../components/ConfirmationModal/ConfirmationModal';
import EmailsTab from '../../../Company/Entity/EntityShow/subComponents/EmailsTab/EmailsTab';
import VehicleRunShowBilling from './subComponents/VehicleRunShowBilling/VehicleRunShowBilling';
import VehicleRunShowRoutes from './subComponents/VehicleRunShowRoutes/VehicleRunShowRoutes';
import VehicleRunShowFinancials from './subComponents/VehicleRunShowFinancials/VehicleRunShowFinancials';
import Pdf from '../../../../api/controllers/File/Pdf/Pdf';
import Item from '../../../../api/controllers/File/Item/Item';
import DownloadHelper from '../../../../utils/DownloadHelper/DownloadHelper';
import VehicleRunShowJobs from './subComponents/VehicleRunShowJobs/VehicleRunShowJobs';
import VehicleRunHelper from '../../../../utils/VehicleRunHelper/VehicleRunHelper';
import FinalizeRun from '../../../../components/modals/FinalizeRun/FinalizeRun';
import SendVehicleDetails from '../../../../components/modals/SendVehicleDetails/SendVehicleDetails';
import FuelHelper from '../../../../utils/FuelHelper/FuelHelper';
import VehicleRunShowCO2 from './subComponents/VehicleRunShowCO2/VehicleRunShowCO2';

const VehicleRunShow = ({ permissions, user }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { movementsVehicleRuns } = permissions;
  const [notFound, setNotFound] = useState(false);
  const [message, setMessage] = useState();
  const [finalizeLoad, setFinalizeLoad] = useState(false); // This can be converted to a useArray if necessary to allow others to load, care for race conditions
  const [drsLoading, setDrsLoading] = useState(false);
  const [emailModal, setEmailModal] = useState(false);
  const [modalAlert, setModalAlert] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [finalModal, setFinalModal] = useState();
  const [sendDetailsModal, setSendDetailsModal] = useState();

  const { data, dataLoading, refresh } = useApi({
    call: () => VehicleRun.show(id),
    handleError: () => setNotFound(true),
    dependencies: [id],
  });

  useEffect(() => {
    document.title = data?.runReference || id;
  }, [data]);

  // Specifically used to trigger the finalize modal for administrators
  const handleFinalize = async () => {
    if (!data.subcontracted && user.administrator) {
      // We need to do this because the getFuelAndBase does the calculation automatically
      const handler = new FuelHelper(data);
      const fuelPrice = await handler._getFuelPrice(data.dateOfUse);
      const ppl = Number(fuelPrice.pencePerLitre);

      const cannotCalcFuel = !data.startMileage || !data.endMileage || isNaN(ppl);

      // I know we usually use permissions, but in this SPECIFIC scenario we use this
      if (cannotCalcFuel) return setFinalModal(ppl);
    }

    const costs = (await VehicleRunHelper.getFuelAndBase(data)) || {};
    VehicleRunHelper.toggleFinalize(data, costs, setFinalizeLoad, setMessage, refresh, () =>
      setSendDetailsModal('finalize'),
    );
  };

  const handleUnFinalize = async () => {
    const costs = (await VehicleRunHelper.getFuelAndBase(data)) || {};
    VehicleRunHelper.toggleFinalize(data, costs, setFinalizeLoad, setMessage, refresh);
  };

  const renderFinalise = () => {
    if (!data) return;
    if (data.closed)
      return (
        <IconButton
          text={'Un-Finalise'}
          icon={faLockOpen}
          onClick={handleUnFinalize}
          className="import-job"
          loading={finalizeLoad}
        />
      );
    return (
      <IconButton text={'Finalise'} icon={faLock} onClick={handleFinalize} loading={finalizeLoad} />
    );
  };

  const handleDriverRunningSheet = () => {
    setDrsLoading(true);
    Pdf.generate({ kind: 'driver_running_sheet', id: data?.id }).then(
      pdfRes =>
        Item.download(pdfRes.id)
          .then(
            item => DownloadHelper.saveFile(pdfRes.fileName, item),
            err => setMessage({ type: 'error', text: formatError(err) }),
          )
          .then(() => setDrsLoading(false)),
      error => {
        setMessage({ type: 'error', text: formatError(error) });
        setDrsLoading(false);
      },
    );
  };

  const handleDelete = () => {
    VehicleRun.destroy(id).then(
      () => navigate('/movements/runs'),
      err => {
        setMessage({ type: 'error', text: formatError(err) });
        setDeleteModal();
      },
    );
  };

  if (notFound) return <NoPage />;
  if (dataLoading) return <LoadingSpinner />;

  return (
    <div className="show-vehicle-run">
      {emailModal && (
        <EmailComposeModal
          user={user}
          parent={data}
          recipientEntity={data.subcontractor}
          templateType={'general_vehicle_run'}
          handleClose={() => setEmailModal(false)}
          fileTabs={[{ tabName: 'General Files' }, { tabName: 'Vehicle Run Files', parent: data }]}
          setAlert={setModalAlert}
          defaultAttachments={[]}
        />
      )}
      {finalModal !== undefined && (
        <FinalizeRun
          run={data}
          ppl={finalModal}
          handleClose={() => setFinalModal()}
          refresh={refresh}
          openSendDetailsModal={() => setSendDetailsModal('finalize')}
        />
      )}
      {sendDetailsModal !== undefined && (
        <SendVehicleDetails
          handleClose={() => setSendDetailsModal()}
          fromFinalize={sendDetailsModal == 'finalize'}
          run={data}
          handleError={setMessage}
        />
      )}
      {deleteModal && (
        <ConfirmationModal
          isOpen
          question={'Are you sure you want to delete this vehicle run?'}
          subText={data.runReference}
          handleCancel={() => setDeleteModal()}
          handleConfirm={handleDelete}
          destructive
          confirmText="Delete"
        />
      )}
      <Breadcrumbs>
        <Link to={'/movements/runs'}>Vehicle Runs</Link>
        <Link to={`/movements/runs/${id}`}>{data.runReference || data.id}</Link>
      </Breadcrumbs>
      <Ribbon>
        <div className="split">
          {movementsVehicleRuns.update && (
            <>
              <IconButton
                text={'Send Email'}
                icon={faEnvelope}
                onClick={() => setEmailModal(true)}
                disabled={!data.subcontractor}
              />
              <IconButton
                text={'Driver Running Sheet'}
                icon={faFile}
                onClick={handleDriverRunningSheet}
                loading={drsLoading}
              />
            </>
          )}
        </div>
        <div className="split">
          {movementsVehicleRuns.update && (
            <>
              {renderFinalise()}
              {!data.closed && (
                <IconButton
                  text={'Edit Vehicle Run'}
                  icon={faPen}
                  onClick={() => navigate(`/movements/runs/${id}/edit`)}
                  className={'edit'}
                />
              )}
            </>
          )}
          {movementsVehicleRuns.destroy && !data.closed && (
            <IconButton
              text={'Delete Vehicle Run'}
              icon={faTrash}
              onClick={() => setDeleteModal(true)}
              className={'destructive'}
            />
          )}
        </div>
      </Ribbon>
      <div className="page-content">
        <Message
          text={message?.text}
          type={message?.type}
          visible={!!message}
          onClick={() => setMessage()}
        />
        <ErrorStatusMessage error={modalAlert} setError={setModalAlert} />
        <h1>{data.runReference}</h1>
        <TabGroup
          labels={[
            'General',
            'Billing',
            'Financials',
            'Jobs',
            'Routes',
            'CO₂',
            'Attachments',
            'Emails',
            'Notes',
          ]}
        >
          <VehicleRunShowGeneral run={data} />
          <VehicleRunShowBilling
            run={data}
            navigate={navigate}
            setModalAlert={setModalAlert}
            canAutoLine={movementsVehicleRuns.update}
          />
          <VehicleRunShowFinancials run={data} />
          <VehicleRunShowJobs
            run={data}
            openSendDetailsModal={() => setSendDetailsModal('button')}
          />
          <VehicleRunShowRoutes run={data} />
          <VehicleRunShowCO2 run={data} />
          <AttachmentsTab
            permissions={permissions}
            parentId={data.id}
            parentType={data.modelName}
            requiredPermit={'movementsVehicleRuns'}
            setError={err => setMessage({ type: 'error', text: formatError(err) })}
          />
          <EmailsTab parentId={data.id} parentType={'Movements::VehicleRun'} />
          <NotesTab
            parentId={data.id}
            parentType={data.modelName}
            permissions={permissions}
            requiredPermit={'movementsVehicleRuns'}
            canDelete={movementsVehicleRuns.destroy}
          />
        </TabGroup>
      </div>
    </div>
  );
};

VehicleRunShow.propTypes = {
  permissions: PropTypes.object,
  user: PropTypes.object,
};

export default VehicleRunShow;
