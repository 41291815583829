import React from 'react';
import PropTypes from 'prop-types';
import { faSort, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import './TablePresenter.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LoadingSpinner from '../../../common/LoadingSpinner/LoadingSpinner';

const TablePresenter = ({
  title,
  headers,
  rows,
  onDrawRow,
  sort,
  onSort,
  onSearch,
  pageElements,
  pageText,
  loading = false,
  children,
  childrenBeforeSearch,
}) => {
  const getIcon = header => {
    const headerAttr = header.sortingAttribute;
    if (!sort || sort.sort !== headerAttr) return faSort;
    if (sort.order === 'desc') return faSortUp;
    return faSortDown;
  };

  const headerCells = headers.map((header, i) => (
    <th
      key={i}
      onClick={header.sortingAttribute ? () => onSort(header) : null}
      className={header.className || null}
    >
      {header.text}
      {header.sortingAttribute && <FontAwesomeIcon icon={getIcon(header)} data-testid="sorticon" />}
    </th>
  ));

  const tableRows = () => rows && rows.map((row, i) => onDrawRow(row, i));

  return (
    <div className="table">
      <div className="table-top">
        <div className="section">
          <div className="title">{title}</div>
        </div>

        <div className="section">
          {childrenBeforeSearch && children}
          <input onChange={onSearch} placeholder="Search..." data-testid="table-search" />
          {!childrenBeforeSearch && children}
        </div>
      </div>
      <table>
        <thead>
          <tr>{headerCells}</tr>
        </thead>
        <tbody>
          {loading ? (
            <tr>
              <td colSpan="99">
                <LoadingSpinner />
              </td>
            </tr>
          ) : (
            tableRows()
          )}
        </tbody>
      </table>
      <div className="footer">
        <div className="table-bottom">
          <div className="page-info">{pageText}</div>
          <div className="page-numbers">{pageElements()}</div>
        </div>
      </div>
    </div>
  );
};

TablePresenter.propTypes = {
  title: PropTypes.string,
  headers: PropTypes.arrayOf(PropTypes.object),
  rows: PropTypes.arrayOf(PropTypes.object),
  onDrawRow: PropTypes.func,
  sort: PropTypes.object,
  onSort: PropTypes.func,
  onSearch: PropTypes.func,
  pageElements: PropTypes.func,
  pageText: PropTypes.string,
  loading: PropTypes.bool,
  children: PropTypes.node,
  childrenBeforeSearch: PropTypes.bool,
};

export default TablePresenter;
