import React, { useEffect, useState } from 'react';
import './CO2JobDropdown.scss';
import CarbonEmissions from '../../../../../../../../api/controllers/CarbonEmissions/CarbonEmissions';
import PropTypes from 'prop-types';
import CO2Lines from '../../../../../../../../components/CO2Lines/CO2Lines';
import CollapsibleFormSection from '../../../../../../../../components/CollapsibleFormSection/CollapsibleFormSection';
import { faBoxesStacked, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

const CO2JobDropdown = ({ job }) => {
  const [carbonEmissions, setCarbonEmissions] = useState();
  const [totalDistance, setTotalDistance] = useState();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    if (dropdownOpen && !carbonEmissions) {
      setError(false);
      setLoading(true);
      CarbonEmissions.job(job.id)
        .then(result => {
          setLoading(false);
          setCarbonEmissions(result);
          setTotalDistance(
            result.breakdown.length == 0
              ? -1
              : result.breakdown
                  .map(i => i.distance || 0)
                  .reduce((partialSum, a) => partialSum + a, 0),
          );
        })
        .catch(e => {
          setLoading(false);
          setError(e.error);
        });
    }
  }, [dropdownOpen]);

  const getStatusText = () => {
    if (job.status == 'cancelled')
      return <div className={`status-indicator cancelled`}>Cancelled</div>;
    if (job.subcontracted) return <div className={`status-indicator`}>Subcontracted</div>;
    if (!job.vehicleRunId) return <div className={`status-indicator`}>Provisional</div>;
    return;
  };

  const getDistanceText = () => {
    if (loading)
      return <FontAwesomeIcon icon={faSpinner} className={'fa-spin'} data-testid={'spinner'} />;
    if (!totalDistance) return 'Expand to calculate total distance';
    if (totalDistance == -1) return 'No route details available; distance cannot be calculated';
    else return `${Number(totalDistance).toFixed(2)} km`;
  };

  return (
    <div className={'tab-content'}>
      <CollapsibleFormSection
        showTitleOnHover={false}
        title={
          <div className="co2-dropdown-header">
            <FontAwesomeIcon icon={faBoxesStacked} />
            <Link
              className="job-link"
              to={`/jobs/bookings/${job.id}`}
              target="_blank"
              rel="noopener noreferrer"
              title=""
            >
              {job.jobReference}
            </Link>
            {getStatusText()}
          </div>
        }
        onClick={() => setDropdownOpen(!dropdownOpen)}
        open={dropdownOpen}
        optionalElement={
          <div className={`dropdown-header-distance ${!(totalDistance >= 0) && 'itallic'}`}>
            {getDistanceText()}
          </div>
        }
      >
        <CO2Lines
          items={carbonEmissions?.breakdown || []}
          loading={loading}
          error={error}
          jobWeight={job.grossWeightKg}
        />
      </CollapsibleFormSection>
    </div>
  );
};

CO2JobDropdown.propTypes = {
  job: PropTypes.object,
};

export default CO2JobDropdown;
