import React, { useEffect, useState } from 'react';
import FormSection from '../../../../../../components/FormSection/FormSection';
import './CO2Tab.scss';
import PropTypes from 'prop-types';
import Booking from '../../../../../../api/controllers/Job/Booking/Booking';
import CO2JobDropdown from './subComponents/CO2JobDropdown/CO2JobDropdown';
import DateHelper from '../../../../../../utils/DateHelper/DateHelper';
import FormField from '../../../../../../components/FormField/FormField';
import LoadingSpinner from '../../../../../../common/LoadingSpinner/LoadingSpinner';

const CO2Tab = ({ entityId }) => {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [jobs, setJobs] = useState([]);
  const { start, end } = DateHelper.getStartAndEndOfMonth(new Date());
  const [startDate, setStartDate] = useState(start.toISOString().split('T')[0]);
  const [endDate, setEndDate] = useState(end.toISOString().split('T')[0]);

  useEffect(() => {
    setLoading(true);
    setError(false);
    Booking.all({ entityId, startDate, endDate: DateHelper.tomorrow(endDate) })
      .then(result => {
        setLoading(false);
        setJobs(result.items);
      })
      .catch(e => {
        setLoading(false);
        setError(e.error);
      });
  }, [startDate, endDate]);

  const getSectionContent = () => {
    return jobs.length > 0 ? (
      jobs?.map(job => {
        return <CO2JobDropdown key={job.id} job={job} />;
      })
    ) : (
      <div className="no-jobs">
        {loading ? (
          <LoadingSpinner />
        ) : (
          error || 'No jobs were booked for this entity within the specified time range'
        )}
      </div>
    );
  };

  return (
    <div className={'tab-content'}>
      <FormSection
        title={'CO₂'}
        optionalElement={
          <div className="date-pickers">
            <FormField label={'From'} className="co2-date">
              <input
                data-testid={'co2-start-date'}
                type={'date'}
                onChange={e => {
                  if (e.target.value != '') setStartDate(e.target.value);
                }}
                value={startDate}
              />
            </FormField>
            <FormField label={'to'} className="co2-date">
              <input
                data-testid={'co2-end-date'}
                type={'date'}
                onChange={e => {
                  if (e.target.value != '') setEndDate(e.target.value);
                }}
                value={endDate}
              />
            </FormField>
          </div>
        }
      >
        {getSectionContent()}
      </FormSection>
    </div>
  );
};

CO2Tab.propTypes = {
  entityId: PropTypes.number,
};

export default CO2Tab;
