import React, { useState } from 'react';
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';
import { Link, useNavigate } from 'react-router-dom';
import Message from '../../../common/layout/Message/Message';
import './ReportIndex.scss';
import useApi from '../../../common/hooks/useApi/useApi';
import Report from '../../../api/controllers/Report/Report';
import { formatError } from '../../../utils/Formatters/Errors/formatError';
import Table from '../../../components/Table/Table/Table';
import LoadingSpinner from '../../../common/LoadingSpinner/LoadingSpinner';
import ReportRow from './ReportRow/ReportRow';
import ErrorStatusMessage from '../../../common/ErrorStatusMessage/ErrorStatusMessage';
import TabGroup from '../../../components/TabGroup/TabGroup';
import ScheduleReportIndex from './ScheduleReportIndex/ScheduleReportIndex';

/* We want to sort by Name and Description, but not Dates Required */
const headers = [
  { text: 'Name', sortingAttribute: 'name' },
  { text: 'Description', sortingAttribute: 'description' },
  { text: 'Dates Required' },
  { text: 'Filter' },
  { text: 'Actions' },
];

/*
  Why? The ReportIndex should hold all standard report types.
    Reports need a date range most of the time, this allows them to generate
      data from all jobs, runs or other in that space
    Reports that do not need this, such as an Outstanding Sales Line Item will
      always allow you to generate a report, ignoring the range if given
*/
const ReportIndex = () => {
  const [message, setMessage] = useState(); /* The red message at the top of the index */
  const [warning, setWarning] = useState(); /* The yellow toast message */
  const [from, setFrom] = useState(''); /* The date input "from" */
  const [to, setTo] = useState(''); /* The date input "to" */
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState();

  /* Handles errors coming from the Report.all endpoint */
  const handleError = err => setMessage({ type: 'error', text: formatError(err) });

  const { reports, reportsLoading } = useApi({ call: Report.all, name: 'reports', handleError });

  document.title = 'All Reports';

  // When the name of a report is clicked, check whether we need a date range
  // If we do, throw a small warning toast if not filled in, else redirect
  // If we don't, simply redirect using navigate
  const handleRedirect = (row, entity) => {
    if (row.defaultDateField) {
      if (!from || !to)
        return setWarning({ error: 'You must specify a date range for this report.' });

      if (new Date(to) < new Date(from))
        return setWarning({ error: 'Invalid date range entered.' });

      if (row.background) {
        const params = entity?.id
          ? { startDate: from, endDate: to, entityId: entity?.id }
          : { startDate: from, endDate: to };
        Report.schedule(row.id, params)
          .then(() => {
            setCurrentTab(1);
          })
          .catch(error => handleError(error));
      } else {
        navigate(
          `/reports/standard/${row.id}?startDate=${from}&endDate=${to}${
            entity?.id ? `&entityId=${entity?.id}` : ''
          }`,
        );
      }
    } else {
      navigate(`/reports/standard/${row.id}`);
    }
  };

  return (
    <div className="report-index">
      <Breadcrumbs>
        <Link to="/reports/standard">Reports</Link>
      </Breadcrumbs>
      <div className="page-content">
        {message && (
          <Message type={message.type} text={message.text} visible onClick={() => setMessage()} />
        )}
        {warning && <ErrorStatusMessage error={warning} setError={setWarning} />}
        <TabGroup labels={['General', 'Scheduled']} defaultTab={currentTab}>
          {reportsLoading ? (
            <LoadingSpinner />
          ) : (
            <div className="reports-table-container" onFocus={() => setCurrentTab(0)}>
              <Table
                title={'Standard Reports'}
                headers={headers}
                data={reports}
                searchable={['name']}
                onDrawRow={row => (
                  <ReportRow key={row.id} row={row} handleRedirect={handleRedirect} />
                )}
                childrenBeforeSearch
              >
                <div className="report-date-selectors">
                  From
                  <input
                    type={'date'}
                    value={from}
                    onChange={e => setFrom(e.target.value)}
                    autoFocus
                    data-testid="from-date"
                  />
                  to
                  <input
                    type={'date'}
                    value={to}
                    onChange={e => setTo(e.target.value)}
                    data-testid="to-date"
                  />
                </div>
              </Table>
            </div>
          )}
          <ScheduleReportIndex setMessage={setMessage} />
        </TabGroup>
      </div>
    </div>
  );
};

export default ReportIndex;
