import React from 'react';
import PropTypes from 'prop-types';
import LoadingSpinner from '../../common/LoadingSpinner/LoadingSpinner';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CO2LinesRow from './CO2LinesRow/CO2LinesRow';

const CO2Lines = ({ type, items, loading, error, jobWeight }) => {
  const getTotalWTT = () => {
    return Number(
      items
        .map((item, index) => {
          const weightToCalc = type == 'vehicleRun' ? effectiveWeightAt(items, index) : jobWeight;
          return item.distance
            ? item.distance *
                getFactors(item.factors).wtt *
                (getFactors(item.factors).type == 'tkm' ? weightToCalc / 1000 : 1)
            : 0;
        })
        .reduce((partialSum, a) => partialSum + a, 0),
    ).toFixed(2);
  };

  const getTotalTTW = () => {
    return Number(
      items
        .map((item, index) => {
          const weightToCalc = type == 'vehicleRun' ? effectiveWeightAt(items, index) : jobWeight;
          return item.distance
            ? item.distance *
                getFactors(item.factors).ttw *
                (getFactors(item.factors).type == 'tkm' ? weightToCalc / 1000 : 1)
            : 0;
        })
        .reduce((partialSum, a) => partialSum + a, 0),
    ).toFixed(2);
  };

  const getFactors = factors => {
    if (type == 'vehicleRun' && factors.km) {
      return { type: 'km', ttw: factors.km.ttw, wtt: factors.km.wtt };
    } else if (factors.tkm) {
      return { type: 'tkm', ttw: factors.tkm.ttw, wtt: factors.tkm.wtt };
    } else {
      return { type: 'tkm', ttw: 0, wtt: 0 };
    }
  };

  function effectiveWeightAt(route, startIndex) {
    const destination = route.slice(startIndex).find(place => place.factors.km);
    return destination ? destination.totalWeight : null;
  }

  if (loading) return <LoadingSpinner />;
  if (items.length === 0 || error)
    return (
      <div className={'no-route'}>
        {error || 'No route details available; emissions cannot be calculated'}
      </div>
    );
  return (
    <table border="1" frame="void" rules="rows" className={'co2-table'}>
      <thead>
        <tr>
          <th>Location</th>
          <th>{type == 'vehicleRun' ? 'Capacity' : 'Transport Type'}</th>
          <th>Kilometres</th>
          <th>Weight (kg)</th>
          <th>WTT (kg)</th>
          <th>TTW (kg)</th>
          <th>CO2e (kg)</th>
          {type == 'vehicleRun' && (
            <th>
              <FontAwesomeIcon icon={faInfoCircle} />
            </th>
          )}
        </tr>
      </thead>
      <tbody>
        {items.map((li, i) => {
          return (
            <CO2LinesRow
              key={i}
              type={type}
              item={li}
              factors={li.distance && getFactors(li.factors)}
              weight={type == 'vehicleRun' ? effectiveWeightAt(items, i) : jobWeight}
            />
          );
        })}
        <tr>
          <td className={'small'}></td>
          <td className={'small'}></td>
          <td className={'small'}></td>
          <td className={'small'}></td>
          <td className={'bold'}>{getTotalWTT()}</td>
          <td className={'bold'}>{getTotalTTW()}</td>
          <td className={'bold'}>{(Number(getTotalWTT()) + Number(getTotalTTW())).toFixed(2)}</td>
        </tr>
      </tbody>
    </table>
  );
};

CO2Lines.propTypes = {
  type: PropTypes.oneOf(['job', 'vehicleRun']),
  items: PropTypes.array,
  loading: PropTypes.bool,
  error: PropTypes.string,
  jobWeight: PropTypes.number,
};

export default CO2Lines;
