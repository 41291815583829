import React, { useEffect, useState } from 'react';
import TablePresenter from '../../../components/Table/TablePresenter/TablePresenter';
import useAjaxTable from '../../../common/hooks/ajaxHooks/useAjaxTable/useAjaxTable';
import EmailTemplate from '../../../api/controllers/EmailTemplate/EmailTemplate';
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';
import Ribbon from '../../../components/Ribbon/Ribbon';
import IconButton from '../../../common/button/IconButton/IconButton';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faPlus, faStar, faTrash } from '@fortawesome/free-solid-svg-icons';
import { humanize, labelForEnum } from '../../../utils/EnumHelper/EnumHelper';
import { formatError } from '../../../utils/Formatters/Errors/formatError';
import './EmailTemplate.scss';
import ErrorStatusMessage from '../../../common/ErrorStatusMessage/ErrorStatusMessage';
import ConfirmationModal from '../../../components/ConfirmationModal/ConfirmationModal';
import Message from '../../../common/layout/Message/Message';
import SelectTemplateType from '../../../components/SelectTemplateType/SelectTemplateType';
import { emailTemplateTypes } from '../../../strings/enums';
import { useNavigate } from 'react-router';
import LoadingSpinner from '../../../common/LoadingSpinner/LoadingSpinner';

const headers = [
  { text: 'Name', sortingAttribute: 'name' },
  { text: 'Type', sortingAttribute: 'type' },
  { text: 'Associated Model' },
  { text: 'Actions' },
];

const EmailTemplatesIndex = () => {
  const navigate = useNavigate();
  const [disableAll, setDisableAll] = useState(false);
  const [error, setError] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectTemplate, setSelectTemplate] = useState(false);
  const [templateName, setTemplateName] = useState();
  const [templateId, setTemplateId] = useState();
  const [message, setMessage] = useState();
  const [buttonLoading, setButtonLoading] = useState([]);

  useEffect(() => {
    document.title = 'Email Templates';
  }, []);

  const { data, refresh, sort, onSort, onSearch, pageElements, pageText, loading } = useAjaxTable({
    call: EmailTemplate.all,
    defaultSort: { sort: headers[0].sortingAttribute, order: 'asc' },
  });

  const setTemplateAsDefault = id => {
    setDisableAll(true);
    setButtonLoading([...buttonLoading, id]);
    EmailTemplate.markAsMain(id, { templateType: { defaultTemplateId: id } })
      .then(
        async () => {
          setButtonLoading(buttonLoading.filter(item => item !== id));
          await refresh();
          setError();
        },
        err => {
          setButtonLoading(buttonLoading.filter(item => item !== id));
          setError({ error: err.error, status: 'Error' });
        },
      )
      .finally(() => setDisableAll(false));
  };

  const handleDelete = () => {
    setButtonLoading([...buttonLoading, 'delete']);
    templateId &&
      EmailTemplate.destroy(templateId)
        .then(
          async () => {
            await refresh();
            setMessage();
          },
          err => {
            setMessage(formatError(err));
          },
        )
        .finally(() => {
          setButtonLoading(buttonLoading.filter(item => item !== 'delete'));
          setTemplateId();
          setTemplateName();
          setDeleteModal(false);
        });
  };

  const handleEdit = id => {
    navigate(`/emails/templates/${id}/edit`);
  };

  const isDisabled = item => {
    if (disableAll) return true;
    return item.templateType.defaultTemplateId === item.id;
  };

  const isLoading = id => {
    return buttonLoading.filter(item => item === id).length > 0;
  };

  const renderActions = item => {
    return (
      <div className="actions">
        <IconButton
          text={'Set as default'}
          icon={faStar}
          onClick={() => setTemplateAsDefault(item.id)}
          disabled={isDisabled(item)}
          loading={isLoading(item.id)}
          className={`small success ${isDisabled(item) ? 'success-disabled' : ''}`}
        />
        <IconButton
          text={'Edit'}
          icon={faPen}
          onClick={() => handleEdit(item.id)}
          className="small edit"
        />
        <IconButton
          text={'Delete'}
          icon={faTrash}
          onClick={() => {
            setDeleteModal(true);
            setTemplateName(item.name);
            setTemplateId(item.id);
          }}
          className="small destructive"
        />
      </div>
    );
  };

  const handleDrawRow = (item, i) => {
    return (
      <tr key={i} className={i % 2 === 0 ? '' : 'coloured'}>
        <td>
          <Link to={`/emails/templates/${item.id}`}>
            <FontAwesomeIcon icon={faStar} className={isDisabled(item) ? '' : 'faded'} />
            {item.name}
          </Link>
        </td>
        <td>{labelForEnum(emailTemplateTypes, item.templateType.name)}</td>
        <td>{humanize(item.templateType.associatedModel)}</td>
        <td>{renderActions(item)}</td>
      </tr>
    );
  };
  if (loading) return <LoadingSpinner />;
  if (!data) return null;

  return (
    <div className="email-template-index">
      <Breadcrumbs>
        <Link to={'/emails/templates'}>Email Templates</Link>
      </Breadcrumbs>
      <Ribbon>
        <IconButton
          text={'Add Email Template'}
          icon={faPlus}
          onClick={() => setSelectTemplate(true)}
        />
      </Ribbon>

      <ConfirmationModal
        question={'Are you sure you want to delete this Template?'}
        subText={templateName}
        confirmText={'Delete'}
        isOpen={deleteModal}
        handleConfirm={handleDelete}
        handleCancel={() => {
          setDeleteModal(false);
          setTemplateName();
          setTemplateId();
        }}
        loading={isLoading('delete')}
      />
      <SelectTemplateType isOpen={selectTemplate} setIsOpen={setSelectTemplate} />
      <div className="page-content">
        <Message
          text={message}
          type={'error'}
          onClick={() => setMessage()}
          visible={!!message}
          onClose={setMessage}
        />
        <ErrorStatusMessage error={error} setError={setError} />

        <div className="index-table">
          <TablePresenter
            title={'Email Templates'}
            headers={headers}
            rows={data.items}
            onDrawRow={handleDrawRow}
            sort={sort}
            onSort={onSort}
            onSearch={onSearch}
            pageElements={pageElements}
            pageText={pageText}
            loading={loading}
          />
        </div>
      </div>
    </div>
  );
};

EmailTemplatesIndex.propTypes = {};

export default EmailTemplatesIndex;
