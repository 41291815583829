import React, { useEffect, useState } from 'react';
import FormSection from '../../../../../../components/FormSection/FormSection';
import CO2Lines from '../../../../../../components/CO2Lines/CO2Lines';
import './BookingShowCO2.scss';
import CarbonEmissions from '../../../../../../api/controllers/CarbonEmissions/CarbonEmissions';
import { useParams } from 'react-router';
import PropTypes from 'prop-types';

const BookingShowCO2 = ({ data }) => {
  const { id } = useParams();
  const [breakdown, setBreakdown] = useState([]);
  const headerElement = (
    <div className="optional-header">{data.subcontracted ? 'Subcontracted' : 'Provisional'}</div>
  );
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    CarbonEmissions.job(id)
      .then(result => {
        setLoading(false);
        setBreakdown(result.breakdown);
      })
      .catch(e => {
        setLoading(false);
        setError(e.error);
      });
  }, []);

  return (
    <div className={'tab-content'}>
      <FormSection
        title={'CO₂'}
        optionalElement={data.subcontacted || (!data.vehicleRunId && headerElement)}
      >
        <CO2Lines
          items={breakdown}
          loading={loading}
          error={error}
          jobWeight={data.grossWeightKg}
        />
      </FormSection>
    </div>
  );
};

BookingShowCO2.propTypes = {
  data: PropTypes.object,
};

export default BookingShowCO2;
