import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faClock, faPlay, faTimes } from '@fortawesome/free-solid-svg-icons';
import './ReportRow.scss';
import IconButton from '../../../../common/button/IconButton/IconButton';
import GenericAjaxDropdown from '../../../../common/GenericAjaxDropdown/GenericAjaxDropdown';
import Entity from '../../../../api/controllers/Company/Entity/Entity';

const ReportRow = ({ row, handleRedirect }) => {
  const [entityFilter, setEntityFilter] = useState(null);

  return (
    <tr className="report-row" data-testid="report-row">
      <td className="small">{row.name}</td>
      <td>{row.description}</td>
      <td data-testid="rr-date-req" className="rr-date-req">
        {<FontAwesomeIcon icon={row.defaultDateField ? faCheck : faTimes} />}
      </td>
      <td data-testid="rr-filter">
        {row.optionalParameters.entityId && (
          <GenericAjaxDropdown
            value={entityFilter}
            call={Entity.all}
            onDisplay={ent => ent?.internalName}
            onSelect={ent => setEntityFilter(ent)}
            testId={'rr-filter-entity'}
            onClear={() => setEntityFilter(null)}
          />
        )}
      </td>
      <td data-testid="rr-actions" className="small">
        {
          <IconButton
            text={row.background ? 'Schedule' : 'Run'}
            icon={row.background ? faClock : faPlay}
            className={`small ${row.background && 'import-job'} report-button`}
            onClick={() => handleRedirect(row, entityFilter)}
          />
        }
      </td>
    </tr>
  );
};

ReportRow.propTypes = {
  row: PropTypes.object.isRequired, // A single Reports::Standard object
  handleRedirect: PropTypes.func.isRequired, // Handles special logic upon redirect
};

export default ReportRow;
